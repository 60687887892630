import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import './404.sass'

const NotFound = () => (
  <div className="page-not-found">
    <Helmet>
      <title>Ironstar - Page Not Found</title>
    </Helmet>

    <div className="img-wrap">
      <img className="img-logo" src="/assets/logo.png" alt="Brandmark" />
      <img src="/assets/logo-txt.svg" alt="Ironstar" />
    </div>
    <div>
      Page not found!
      <br />
      Click <Link to="/">here</Link> to return to the home page
    </div>
  </div>
)

export default NotFound
